import React, { useContext } from "react";
import styled from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import Close from "../../../../../assets/images/modal/close.svg";
import Modal from "../../../../../Components/Modal/Modal";
import TagsContext from "../../../../../Contexts/TagsContext/TagsContext";
import TransactionContext from "../../../../../Contexts/Transaction/TransactionContext";

const Tags = () => {
  const checkTags = useContext(TagsContext);
  const checkcefilist = useContext(TransactionContext);

  return (
    <div className="tag_item">
      <Modal>
        <StyledWrapper>
          <StyleClose>
            <img src={Close} alt="flashift" onClick={checkTags.tagsModalHide} />
          </StyleClose>
          <ul>
            {checkcefilist.exchangelist[checkTags.tagId]?.tags.map((item) => {
              return (
                <div className="tagItem">
                  <h4>{item}</h4>
                  <p>
                    {item === "Best Rate"
                      ? "The exchange service provides the most competitive rate for the transaction compared to other available services, without taking into account any other features."
                      : item === "Guaranteed by Flashift"
                      ? "The transaction is fully guaranteed by Flashift, which implies that if you do not receive your funds in accordance with the terms of use of the exchange service, we will provide you with a full reimbursement."
                      : item === "Recommended"
                      ? "Flashift's cutting-edge AI system is designed to continuously gather user feedback from multiple online sources and analyze various features such as exchange rates, KYC levels, and transaction speed. The system recommends the exchange service."
                      : item === "Best in KYC"
                      ? "Flashift's cutting-edge AI system is designed to continuously gather user feedback from multiple online sources and analyze various features such as exchange rates, KYC levels, and transaction speed. The system has identified that the exchange service has the lowest KYC level compared to other exchange services in the list."
                      : item === "No Hold"
                      ? "According to the Flashift AI system, the exchange service does not hold your funds for KYC purposes as per their Terms of Use and AML/KYC policies. In case your funds are held for KYC and you do not wish to comply, you can be assured of a full refund."
                      : item === "Trusted Rate"
                      ? "Flashift's cutting-edge AI system is designed to continuously gather user feedback from multiple online sources and analyze various features. The system has identified that the exchange service delivers the most accurate and reliable rate, ensuring minimal discrepancies. This tag helps users choose the provider with the best rate and the highest rate trustworthiness, offering a better and more predictable exchange experience."
                      : ""}
                  </p>
                </div>
              );
            })}
          </ul>
        </StyledWrapper>
      </Modal>
    </div>
  );
};

const StyledWrapper = styled.div`
  padding: 10px;
  color: ${(props) => props.theme.color.ColorTwo};
  .tagItem {
    margin-bottom: 10px;
    h4 {
      color: #f39c12;
    }
  }
`;
const StyleClose = styled.button`
  position: absolute;
  right: 15px;
  top: 20px;
`;
export default Tags;
